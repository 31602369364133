<template>
  <div class="announcement">
    <Header title="公告" theme="light" />
    <div class="zone__main">
      <div v-if="noticeData != ''">
        <div
          class="wrap__content"
          v-for="(item,i) in noticeData"
          :key="item.id"
          @click="$router.push({ name: 'announcementDetails',params: { cId:i } })"
        >
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.updatedAt }}</div>
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import NoData from "@/components/NoData.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "announcement",
  components: {
    Header,
    NoData,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState("notice", ["noticeData"]),
  },
  created() {
    this.getNotice()
  },
  methods: {
    ...mapActions("notice", ["getNotice"]),
  },
};
</script>
<style lang="scss" scoped>
.announcement {
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: scroll;
  .zone__main {
    width: 100%;
    .wrap__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px dashed var(--light-gray);
      @media (min-width:480px){
        justify-content: center;
      }
      div {
        width: 100%;
        text-align: left;
        &.title {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
        &.time {
          color: var(--gray);
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
